header {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 99;
    background-color: $white;
    @include breakpoint (large up) {
      padding: 1rem 0 0;
    }
  .header-text {
    display: flex;
    p {
      padding: .2rem .5rem;
      margin-bottom: 0;
      color: $medium-gray;
      font-size: .8rem;
      @include breakpoint (medium up) {
        font-size: 1rem;
        display: flex;
        width: 100%;
        justify-content: space-between;
      }
    }
  }
}

.title-bar {
  background-color: $white;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid $black;
  div {
    display: flex;
    align-items: center;
    ul {
      margin: 0;
      margin-left: 1rem;
    }
  }
  h1 {
    font-size: .8rem;
    margin-bottom: 0;
    @include breakpoint (medium up) {
      font-size: 1.2rem;
    }
    span {
      color: $medium-gray;
    }
  }
}
nav {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .custom-menu {
    list-style: none;
    margin-left: 0;
    margin-bottom: 0;
    background-color: $white;
    border-bottom: 1px solid $body-font-color;
    li {
      margin-left: 0.5rem;

      a {
        font-size: 1rem;
        text-transform: uppercase;
        margin: 0.5rem 0;
        display: block;
        text-decoration: none;
        &:hover {
          text-decoration: underline;
        }
        &.active {
          font-weight: 700;
        }
        img {
          width: 25px;
          margin-bottom: 0.2rem;
          @include breakpoint (medium up) {
            margin-right: .5rem;
          }
        }
      }
    }
  }
}
  .en-menu {
    list-style: none;
    margin-left: .5rem;
    margin-bottom: .5rem;
    @include breakpoint (medium up) {
      margin-top: 1rem;
      display: flex;
      align-items: center;
      margin-left: 0;
      margin-bottom: 0;
    }
   img {
      width: 25px;
      margin-bottom: 0.2rem;
      @include breakpoint (medium up) {
        margin-right: .5rem;
      }
    }
  }


