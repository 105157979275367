footer {
  background-color: $black;
  padding: 0.5rem 0;
  ul {
    list-style: none;
    margin: 0;
    text-align: center;
    li {
      color: $white;
      font-size: 0.8rem;
      display: inline-block;
      padding: 0 0.5rem;
      &:first-child {
        padding: 0.4rem 0
      }
      a {
        color: $white;
        &:hover {
          color: $white;
        }
      }
    }
  }
}
