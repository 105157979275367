.privacy-container {
  padding-top: 4rem;
  @include breakpoint (large up) {
    padding-top: 5rem;
  }
}

section {
  padding: 2rem 0;
}

a:hover {
  text-decoration: underline;
}

h1 {
  font-weight: 700;
  color: $body-font-color;
}

h2 {
	margin-bottom: 1.5rem;
  font-weight: 700;
  border-bottom: 1px solid $body-font-color;
  text-transform: uppercase;
  color: $black;
  width: 50%;
}

h3 {
  color: $black;
  font-weight: 700;
}

.invisible-link:hover {
  text-decoration: none;
}

.button {
  border: 1px solid $body-font-color;
  color: $body-font-color;
  &:hover {
    color: $body-font-color;
  }
}

.reveal {
  @include breakpoint (small only) {
    height: auto;
    min-height: auto;
  }
}

iframe {
  margin-bottom: 1rem;
}

.demo-link {
  margin-top: 1rem;
  display: block;
  font-weight: 700;
}

.collage-studio {
  margin-top: 3rem;
}

.contact-wrapper {
  padding-bottom: 0;
  img {
    display: inline-block;
  }
  img.logo {
    margin-top: 4rem;
    width: 100px;
  }
  img.stephan-becker {
    float: right;
    width: 200px;
  }
  h3 {
    margin-bottom: 2rem;
  }
}

.intro-wrapper {
  position: relative;
  margin-top: 2rem;
  @include breakpoint (medium up) {
    margin-top: 4.8rem;
  }
   .image-container {
    position: relative;
    background-image: url(../img/stephan-becker.jpg);
    display: flex;
    width: 100%;
    height: 15rem;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    @include breakpoint (medium up) {
      min-height: 600px;
      background-attachment: fixed;
    }
    @include breakpoint (large up) {
      min-height: 87vh;
    }
  }
  .text-container {
    padding: .8rem;
    height: auto;
    @include breakpoint (medium up) {
      color: $white;
      width: 500px;
      height: auto;
      position: absolute;
      left: 80%;
      top: 50%;
      transform: translate(-80%, -50%);
      background-color: rgba(0,0,0,0.3);
    }
    h1 {
      @include breakpoint (small only) {
        color: $black;
        border-bottom: 1px solid $black;
        margin-bottom: 1.5rem;
      }
    }
  }
}

